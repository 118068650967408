import * as React from 'react';
import { useState } from 'react';

import {
  List,
  Datagrid,
  TextField,
  ReferenceInput,
  AutocompleteInput,
  Button,
  Confirm,
  useDataProvider,
  useNotify,
  useTranslate,
  useRecordContext,
  BooleanField,
} from 'react-admin';
import { Box, Modal, Typography, Button as MuiButton, DialogContentText } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import DatetimeField from 'src/components/DatetimeField';
import { useMutation } from 'react-query';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';

export const UserIcon = PeopleIcon;

const filters = [
  <ReferenceInput source="oid" reference="organizations" alwaysOn>
    <AutocompleteInput optionValue="oid" optionText="name" label="resources.organizations.name" sx={{ width: 450 }} />
  </ReferenceInput>,
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '3px',
  p: 4,
};

export const ResetButton: React.FC = () => {
  const notify = useNotify();
  const translate = useTranslate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { email, id } = useRecordContext();

  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const onSuccess = (result: { data: { password: string } }) => {
    setMessage(translate('messages.resetUserPassword', { password: result.data.password }));
    setOpen(true);
  };
  const { mutateAsync, isLoading } = useMutation(() => dataProvider.invoke(`users/${id}/resetPassword`, {}), {
    onSuccess,
  });
  const handleClose = () => {
    setOpen(false);
  };

  const name = translate(`resources.users.resetPassword.title`);

  return (
    <div>
      <Button color="primary" onClick={() => setIsDialogOpen(true)} label="label.reset" />
      <Confirm
        isOpen={isDialogOpen}
        loading={isLoading}
        title={name}
        content={
          <>
            <DialogContentText>
              email: <strong>{email}</strong>
            </DialogContentText>
            <DialogContentText>{translate('messages.confirm_invoke', { name })}</DialogContentText>
          </>
        }
        confirm={'label.invoke'}
        ConfirmIcon={() => (isLoading ? <PendingIcon /> : <CheckCircle />)}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={async () => {
          try {
            await mutateAsync();
            notify('notification.completed', {
              type: 'success',
            });
          } catch (e) {
            console.error(e);
            notify('error.unknown', {
              type: 'error',
            });
          } finally {
            setIsDialogOpen(false);
          }
        }}
      />
      <Modal open={open}>
        <Box sx={style}>
          <Typography>{message}</Typography>
          <Grid container justifyContent="flex-end">
            <MuiButton
              sx={{ mt: 4 }}
              variant="contained"
              disableElevation
              startIcon={<CloseIcon />}
              onClick={handleClose}
            >
              閉じる
            </MuiButton>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export const UserList: React.FC = () => (
  <List filters={filters} perPage={100} sort={{ field: 'createdAt', order: 'DESC' }} empty={false}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField source="name" sortable={false} />
      <TextField source="email" sortable={false} />
      <TextField source="firebaseId" sortable={false} />
      <DatetimeField source="validFrom" sortable={false} />
      <DatetimeField source="validUntil" sortable={false} />
      <TextField source="jobTitleName" sortable={false} />
      <BooleanField source="jobTitleBillable" sortable={false} />
      <DatetimeField source="createdAt" sortable={false} />
      <ResetButton />
    </Datagrid>
  </List>
);
